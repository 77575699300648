import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Weather } from './Weather';

const App: React.FC = () => {
  const [weatherData, setWeatherData] = useState<Weather[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {

        const response = await axios.get<Weather[]>('https://www.suleden.com/WeatherForecast');
        const data = Array.isArray(response.data) ? response.data : [response.data];
        setWeatherData(data);
        setLoading(false);
      } catch (err:any) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Weather Forecast</h1>
      <ul>
        {weatherData.map((day) => (
          <li key={day.date}>
            <strong>Date:</strong> {day.date} - 
            <strong> Temperature (C):</strong> {day.temperatureC}°C - 
            <strong> Temperature (F):</strong> {day.temperatureF}°F - 
            <strong> Summary:</strong> {day.summary}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;